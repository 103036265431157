
import { defineComponent } from "vue";
export default defineComponent({
  name: "Navbar",
  data() {
    return {
      live: "",
    };
  },
  mounted: function () {
    this.$router.getRoutes().forEach((route) => {
      if (route.meta.live) this.live = route.path;
    });
  },
  computed: {
    route: function (): string {
      return this.$route.path;
    },
    experiments: function (): Array<string> {
      return this.$router
        .getRoutes()
        .filter((route) => route.meta.hidden !== true)
        .map((route) => route.path.replace(/\//, ""))
        .filter((route: string) => route);
    },
  },
});
