import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          type: "button",
          class: _normalizeClass({ active: _ctx.route == '/' })
        }, "index", 2)
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, { to: _ctx.live }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          type: "button",
          class: _normalizeClass({ active: _ctx.route == _ctx.live })
        }, "live", 2)
      ]),
      _: 1
    }, 8, ["to"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.experiments, (path) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        to: `/${path}`,
        key: path
      }, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass({ active: _ctx.route == `/${path}` })
          }, _toDisplayString(path), 3)
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 128))
  ]))
}