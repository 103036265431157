
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";
export default defineComponent({
  name: "App",
  components: {
    Navbar,
  },
  computed: {
    title: function () {
      if (this.$route.path == "/") {
        return "Welcome to the Avalon Lab";
      } else {
        return `Avalon Labs Experiment: ${this.$route.path.replace(/\//, "")}`;
      }
    },
    dev: function () {
      return this.$route.meta["dev"];
    },
  },
});
